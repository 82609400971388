
import { mapState } from 'vuex'
import ModalAddWorkspace from '~/components/workspace/ModalAddWorkspace.vue'
import formsMixin from '~/lib/mixins/forms.mixin'
import authorizationMixin from '~/lib/mixins/authorization.mixin'
import VBadge from '~/components/common/VBadge.vue'
import WorkspaceAvatar from '~/components/common/WorkspaceAvatar.vue'

export default {
  name: 'WorkspacesDropdown',
  components: {
    VBadge,
    WorkspaceAvatar
  },
  mixins: [formsMixin, authorizationMixin],

  async fetch () {
    this.loading = true
    try {
      await this.$store.dispatch('account/retrieveWorkspaces')
      await this.ensureUserCanAccessWorkspace(this.$store.getters['account/currentWorkspaceId'])
    } finally {
      this.loading = false
    }
  },

  data () {
    return {
      loading: false,
      showAddWorkspaceModal: false
    }
  },

  computed: {
    ...mapState({
      currentPlan: state => state.account.subscription?.currentPlan,
      user: state => state.auth.user
    }),
    currentUserId () {
      return this.user?.id
    },
    workspace: {
      get () {
        const workspaceId = this.$route.params.workspaceid || this.user?.currentWorkspaceId
        const workspace = this.$store.getters['account/getWorkspaceById'](workspaceId)

        return workspace || {
          id: workspaceId,
          name: this.user?.currentWorkspaceName,
          users: 1
        }
      },
      set (newWorkspace) {
        !newWorkspace
          ? this.openAddWorkspaceDialog()
          : this.changeCurrentWorkspace(newWorkspace)
      }
    },
    workspaces () {
      return this.$store.state.account.workspaces
    },
    groupedWorkspaces () {
      return this.workspaces.reduce((groups, workspace) => {
        const userId = workspace.userId || 'other'
        const company = workspace.company || 'Other'
        const workspacePlan = workspace.workspacePlan || 'free'

        if (!groups[userId]) {
          groups[userId] = {
            company,
            workspacePlan,
            workspaces: []
          }
        }
        groups[userId].workspaces.push(workspace)
        return groups
      }, {})
    }
  },

  methods: {
    async changeCurrentWorkspace (workspace) {
      try {
        this.clearErrors()
        await this.$store.dispatch('account/changeWorkspace', workspace.id)

        const routesToRedirect = ['/workspaces/new']

        routesToRedirect.includes(this.$route.path)
          ? this.gotoHome()
          : this.gotoWorkspace(workspace.id)
      } catch (error) {
        this.handleError(error)
      }
    },

    async openAddWorkspaceDialog () {
      await this.$oruga.modal.open({
        parent: this,
        component: ModalAddWorkspace,
        custom: true,
        trapFocus: true
      })
    }
  }
}
