
import { stringToColor } from '~/lib/utils/colors.utils'

export default {
  name: 'WorkspaceAvatar',
  props: {
    name: {
      type: String,
      required: false,
      default () {
        return ''
      }
    }
  },
  computed: {
    initials () {
      if (!this.name) {
        return ''
      }
      return this.name
        .split(' ')
        .map(word => word[0])
        .slice(0, 2)
        .join('')
        .toUpperCase()
    },
    bgColor () {
      return stringToColor(this.name).hex
    },
    tooltip () {
      return this.name
    }
  }
}
