export default function ({ app }) {
  if (process.client) {
    const unwatch = setInterval(() => {
      const user = app.$auth?.user
      if (user) {
        const userData = {
          id: user.id,
          name: user.name,
          email: user.email,
          phone: user.phone || null,
          company: user.company || null,
          language: user.language || null
        }

        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'userIdentified',
          user: userData
        })
        clearInterval(unwatch)
      }
    }, 100)

    setTimeout(() => {
      clearInterval(unwatch)
    }, 10000)
  }
}
